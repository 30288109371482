'use strict';

import 'regenerator-runtime/runtime.js';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/modal';
import 'swiper/swiper-bundle';

import { setFormSubmit } from './components/firebase-functions-form-sub';
import { general } from './components/general';
import { animateCounter } from './components/animate-counter';
import { loadIcons } from './components/icons';
import { BannerSlider, TestimonialsSlider, PartnersSlider } from './components/sliders';

import '../scss/main.scss';

const loadYoutubeModals = () => {
  var $videoSrc;
  jQuery('.video-btn').on('click', function () {
    $videoSrc = jQuery(this).data('src');
    jQuery('.modal[id="myModal"]').removeClass('fade').addClass('show');
    jQuery('#video').attr('src', $videoSrc + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0');
  });
  // stop playing the youtube video when I close the modal
  jQuery('.modal-body .close, button.close').on('click', function (e) {
    // a poor man's stop video
    jQuery('.modal').removeClass('show').addClass('fade');
    jQuery('#video').attr('src', null);
  });
};

const loadFormModals = () => {
  jQuery('.btn[data-toggle="modal"]').on('click', function () {
    var target = $(this).attr('data-target');
    jQuery('.modal[id="' + target + '"]')
      .removeClass('fade')
      .addClass('show');
  });
};

const loadSliders = () => {
  document.querySelectorAll('.banner-slider').forEach((bannerSlider) => {
    new BannerSlider(bannerSlider);
  });
  document.querySelectorAll('.testimonials-slider').forEach((testimonialSlider) => {
    new TestimonialsSlider(testimonialSlider);
  });
  document.querySelectorAll('.partners-slider').forEach((partnersSlider) => {
    new PartnersSlider(partnersSlider);
  });
};

const loadCpYear = () => {
  const cpYearContainer = document.querySelector('#cp-year-container');
  if (cpYearContainer) {
    cpYearContainer.innerHTML = new Date().getFullYear();
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    loadFormModals();
    loadYoutubeModals();
    general();
    document.querySelectorAll('.counter-up').forEach((counter) => {
      animateCounter(counter, 2500);
    });
    loadIcons();
    loadSliders();
    setFormSubmit();
    loadCpYear();
  });
} else {
  loadFormModals();
  loadYoutubeModals();
  general();
  document.querySelectorAll('.counter-up').forEach((counter) => {
    animateCounter(counter, 2500);
  });
  loadIcons();
  loadSliders();
  setFormSubmit();
  loadCpYear();
}

/**
 * Icons using Fontawesome SVG icons.
 * Read more about importing icons at @link https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons
 */
'use strict';

import { dom as faDom, library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import {
  faWhatsapp,
  faInstagram,
  faYoutube,
  faSpotify,
  faLinkedin,
  faLinkedinIn,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons/faPlayCircle';

const loadIcons = () => {
  faLibrary.add(
    faPhone,
    faMapMarkerAlt,
    faArrowRight,
    faSearch,
    faEnvelope,
    faCalendarAlt,
    faWhatsapp,
    faPlayCircle,
    faInstagram,
    faYoutube,
    faSpotify,
    faLinkedin,
    faLinkedinIn,
    faFacebook
  );
  faDom.i2svg();
};
export { loadIcons };

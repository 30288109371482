'use strict';

const animateCounter = (el, duration) => {
  if (!el || !duration) return;

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        mainFunction();
        observer.disconnect();
      }
    });
  }, options);

  observer.observe(el);

  function mainFunction() {
    let range = el.dataset.count - 0;
    let current = 0;
    let increment = el.dataset.count > 0 ? 1 : -1;
    let stepTime = Math.abs(Math.floor(duration / range));
    let timer = setInterval(() => {
      current += increment;
      el.innerHTML = current;
      if (current == el.dataset.count) {
        clearInterval(timer);
      }
    }, stepTime);
  }
};

export { animateCounter };

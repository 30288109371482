/**
 * Sliders using Swiper API.
 * Read more about the API at @link https://swiperjs.com/api/
 */
import Swiper, { Pagination, Navigation } from '../../../../node_modules/swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation]);

class BannerSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        slidesPerView: 'auto',
        initialSlide: 1,
        centeredSlides: true,
        breakpoints: {
          576: {
            spaceBetween: 30,
          },
        },
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    super(sliderContainer, swiperParams);
  }
}

class TestimonialsSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        slidesPerView: 'auto',
        initialSlide: 1,
        loop: true,
        spaceBetween: 15,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          576: {
            spaceBetween: 30,
          },
        },
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    super(sliderContainer, swiperParams);
  }
}

class PartnersSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          980: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
        },
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    super(sliderContainer, swiperParams);
  }
}

export { BannerSlider, TestimonialsSlider, PartnersSlider };

'use strict';

const url = 'https://us-central1-planuscarreiras.cloudfunctions.net/contactSubmit'

function displayMessage(formAlert) {
    const message = document.querySelector(formAlert);
    message.classList.remove('d-none');
    setTimeout(() => {
        message.classList.add('d-none');
    }, 10000)
}

export const setFormSubmit = () => {
    var formObj = document.querySelector('#contact-form');
    formObj.addEventListener('submit', (event) => {
        event.preventDefault();
        const formData = new FormData(formObj);
        let data = {};
        formData.forEach(function(value, key) {
            data[key] = value;
        });
        let parsedData = JSON.stringify(data);
        document.querySelector(".form-alerts").style.display = 'none';
        document.querySelector("#loading").style.display = 'block';
        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: parsedData
        }).then(respose => {
            document.querySelector("#loading").style.display = 'none';
            document.querySelector(".form-alerts").style.display = 'block';
            if (respose.status == 200) {
                formObj.querySelector('.form-alerts').innerHTML = '<div class="alert alert-success bg-green" role="alert">Contato enviado com sucesso!</div>';
                formObj.reset();
            } else {
                formObj.querySelector('.form-alerts').innerHTML = '<div class="alert alert-danger" role="alert">Houve um erro no envio do formulário. Contate-nos por telefone ou e-mail.</div>';
            }
        })
    });
};